<template>
  <div class="card">
    <div class="card-body">
      <teammates/>
    </div>
  </div>
</template>
<script>
import Teammates from '../../../components/Account/Teammates/Teammates'

export default {
  components: { Teammates }
}
</script>
